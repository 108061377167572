.mobile-ant-tour {
    width: 100vw;
   
    display: flex;
    justify-content: center;
  
  
  .ant-tour-content {
    min-width: 320px;
    max-width: 320px;
  }
  
  .ant-tour-cover img {
    max-width: 200px;
    max-height: 350px;
  }
}


.ant-tour{
  width: fit-content;
}

.ant-tour-content {
  max-width: 350px;
}